import { useEffect } from 'react';
import { Box, Container, Button } from '@mui/material';
import {
  AutocompleteInput, FunctionField, ReferenceField, ReferenceInput, useSafeSetState, DateField, ArrayInput, SimpleFormIterator,
  required, TopToolbar , Form, useTranslate, TextInput, List, TextField, Datagrid, Pagination
} from 'react-admin';
import { WerifyLayout } from './layout';
import requirePermissions from '../lib/permissions';
import { NoPermissionsLayout } from './no_permissions';
import authProvider, { getPermissionsAndSetThem } from '../lib/auth_provider';
import Loading from './loading';
import _ from "lodash";
import { FileDownload } from '@mui/icons-material';
import { defaultDataProvider } from '../lib/data_provider';
import { useParams } from 'react-router-dom';


const RetrieveAttempts = () => {
  const translate = useTranslate();
  const { id } = useParams();
  const [filter, setFilter] = useSafeSetState({});
  const [storedValues, setStoredValues] = useSafeSetState([]);
  const [permissions, setPermissions] = useSafeSetState("");
  const [loading, setLoading] = useSafeSetState(true);
  const [werifyPointId, setWerifyPointId] = useSafeSetState(null);
  const [pointers, setPointers] = useSafeSetState(null);

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions, setLoading}) }, []);
  useEffect(() => { id && setWerifyPointId(id); }, []);

  useEffect(() => {
    const updateDirective = async () => {
      const dataProvider = await defaultDataProvider();
      const {data: werifyPointData} = await dataProvider.getOne('WerifyPoint', { id: werifyPointId });
      const {data} = await dataProvider.getOne('Rule', { id: werifyPointData.ruleId });
      const directive = JSON.parse(data.directives);
      const all_pointers = _.uniqBy(
        _.flatMap(directive.acceptable_sets, (set) => {
          return _.flatMap(set.required_set, (requiredSet) => {
            const credential_spec = requiredSet.credential_spec;
            const pointers_raw = _.map(credential_spec, e => e.pointer);
            return _.map(pointers_raw, e => { 
              let obj = { id: e, name: e.split("/").pop() };
              return obj;
            });
          });
        }),
      "id");
      setPointers(all_pointers);
    }
    try {

    } catch {}
    werifyPointId && updateDirective();
  }, [werifyPointId]);

  if (loading) return <Loading />;
  if (!requirePermissions.canReadAdminResources(permissions)) { return <NoPermissionsLayout /> };

  const handleSubmit = async (values) => {
    if (!values) return;
    setFilter({werifyPointIdEq: values.werifyPointId, stateEq: "APPROVED"});
    setStoredValues(values.storedValues);
  }

  const getAttempts = async (dataProvider, perPage) => {
    return await dataProvider.getList('Attempt', {
      sort: {field: "id", order: "ASC"}, pagination: { page: 1, perPage }, filter
    });
  }

  const handleExport = async () => {
    const dataProvider = await defaultDataProvider();
    const {total} = await getAttempts(dataProvider, 1);
    const {data} = await getAttempts(dataProvider, total);
    const finalAttempts = _.map(data, a => {
      const att = _.pick(a, ['id', 'werifyPointId', 'ruleName', 'finishedAt', 'storedValues']);
      storedValues.map(storedValue => {
        const label = storedValue.storedValue.split("/").pop();
        att[label] = parseStoredValue(att, storedValue.storedValue)
      });
      return _.omit(att, 'storedValues');
    });

    const csvContent = convertToCSV(finalAttempts);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'export.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertToCSV = (arr) => {
    const header = Object.keys(arr[0]).join(',');
    const values = arr.map(obj => Object.values(obj).join(',')).join('\n');
    return `${header}\n${values}`;
  };

  const parseStoredValue = (record, storedValue) => {
    try {
      const storedJson = JSON.parse(record.storedValues);
      const condition = _.find(storedJson, c => c.pointer === storedValue);
      return condition.value;
    } catch {
      return;
    }
  }

  return <WerifyLayout>
    <Container maxWidth="md" id="lottery">
      <Box textAlign="center" width="100%" height="auto" margin="2em 0 0 0">
        <Form onSubmit={handleSubmit} noValidate id="lottery-form">
          <ReferenceInput source="werifyPointId" reference="WerifyPoint" filter={{archivedAtIsSet: false}} >
            <AutocompleteInput
              label="lottery.choose_werify_point"
              helperText={false}
              optionText={(r) => `${r.id} - ${r.name}` }
              validate={required()}
              defaultValue={id ? parseInt(id) : null }
              onChange={(newValue) => newValue && setWerifyPointId(newValue)} 
            />
          </ReferenceInput>

          <ArrayInput source="storedValues" label="lottery.choose_stored_values" validate={required()} fullWidth>
            <SimpleFormIterator fullWidth disableReordering>
              <AutocompleteInput
                source="storedValue"
                label="lottery.choose_stored_value"
                helperText={false}
                validate={required()}
                choices={pointers}
                fullWidth
              />
            </SimpleFormIterator>
          </ArrayInput>
          
          <Button color="inverted" fullWidth variant="contained" type="submit" id="lottery-submit">
              {translate("lottery.see_attempts")}
          </Button>
        </Form> 
      </Box>
      { !_.isEmpty(filter) &&
        <List
          resource="Attempt"
          empty={false}
          actions={<TopToolbar><Button startIcon={<FileDownload />} onClick={handleExport} variant="contained" color="inverted" id="lottery-export">{translate("lottery.export")}</Button></TopToolbar>}
          pagination={<Pagination rowsPerPageOptions={[50]} />}
          perPage={50}
          sort={{field: "id", order: "DESC"}}
          filter={filter}
        >
          <Datagrid bulkActionButtons={false} id="lottery-list-attempts">
            <TextField source="id" sortable={false} />
            <DateField source="finishedAt" showTime={true} showDate={true} sortable={false} />
            {storedValues.map(storedValue => {
              const label = storedValue.storedValue.split("/").pop();
              return <FunctionField key={label} label={label} source="storedValues" render={record => parseStoredValue(record, storedValue.storedValue) } sortable={false} />
            })}
          </Datagrid>
        </List>
      }
    
    </Container>
  </WerifyLayout>
}

export default RetrieveAttempts;